export const Services = (props) => {
  return (
    <div id='services' className='text-center' style={{backgroundColor: 'black'}}>
      <div className='container'>
        <div className='section-title'>
          <h2>SMM</h2>
          <p>
            Основная задача нашей компании — достигнуть целей Вашего бизнеса. Для достижения лучших результатов мы структурно подходим к изучению проблемы, скрупулёзно изучаем все мелочи и находим наиболее эффективное медиа решение.
          </p>
          <p>
            SMM от компании Centaurus media solutions включает, но не ограничивается ниже описанными услугами:
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className={`${i > 2 ? 'col-md-6' : 'col-md-4'}`}>
                  {' '}
                  {/* <i className={d.icon}></i> */}
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
